import React, { memo, useState, useEffect } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Layout, Menu, Spin, Drawer } from "antd";
import { useWindowSize } from "../../../../app/Utilities/WindowSize";
import { getImageBaseUrl, gtmSetId } from "../../../../app/Utilities";
import {
  getExpandedMenu,
  getWorkshops,
  getWorkshopSelected,
  toogleExpandedMenu,
} from "../../../../stores";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { CkButton, CkIcon } from "../../../../CkUI";
import { Workshop } from "shared";

const CrkLogo = getImageBaseUrl("logos/crk_logo.svg");
const { Sider } = Layout;

const DesktopSideBar = ({
  onChange,
  isMec,
  loadPro,
}: {
  onChange: (evt: any) => void;
  isMec: boolean;
  loadPro: boolean;
}) => {
  const dispatch = useDispatch();
  const expandedMenu = useSelector(getExpandedMenu);
  const windowSize = useWindowSize();
  const match: any = useRouteMatch("/:menu");
  const [openExtraOptions, setOpenExtraOptions] = useState<boolean>(false);
  const workshops: Workshop[] = useSelector(getWorkshops);
  const varWorkshopSelected: number = useSelector(getWorkshopSelected);
  const [isWSEnabled, setIsWSEnabled] = useState<boolean>(true);

  useEffect(() => {
    setIsWSEnabled(workshops[varWorkshopSelected].isEnabled);
  }, [varWorkshopSelected]);

  /**
   * Functions
   */
  const handleAnalytics = (ev) => {
    gtmSetId(ev.currentTarget);
  };
  const clickHandler = (keyObj: { key: string }) => {
    if (windowSize.width < 992 && keyObj.key !== "soporte") {
      dispatch(toogleExpandedMenu(false));
    }
    if (
      windowSize.width >= 992 &&
      keyObj.key === "soporte" &&
      expandedMenu === false
    ) {
      dispatch(toogleExpandedMenu(true));
    }
    onChange(keyObj);
    openExtraOptions && setOpenExtraOptions(false);
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (windowSize.width < 992) {
      dispatch(toogleExpandedMenu(false));
    }
  }, [windowSize.width]);

  useEffect(() => {
    expandedMenu === false && openExtraOptions && setOpenExtraOptions(false);
  }, [expandedMenu]);

  /**
   * Components
   */
  const ToogleMenuButton = () => (
    <CkButton
      className="toogle-menu-button"
      onClick={() => dispatch(toogleExpandedMenu(undefined))}
      icon={expandedMenu ? "arrow-bar-left" : "arrow-bar-right"}
      aria-label="Collapse menu"
      shape="circle"
      variant="text"
    />
  );
  const Content = () => (
    <div
      className={[
        "side-menu-content",
        ...(expandedMenu ? ["expanded"] : []),
      ].join(" ")}
    >
      <div className="side-menu-content-separator" />
      <div className="side-menu-content-header">
        <figure>
          <Link to="/" id="HEADER001" onClick={handleAnalytics}>
            <img src={CrkLogo} alt="Carker Logo" />
          </Link>
        </figure>
        <div>
          <p>Taller</p>
          <ToogleMenuButton />
        </div>
      </div>
      <div className="side-menu-content-body">
        <Menu
          id="sidebar-menu"
          mode="inline"
          onClick={clickHandler}
          selectedKeys={openExtraOptions ? ["soporte"] : [match.params.menu]}
        >
          <Menu.Item
            hidden={isMec}
            className="side-menu-dashboard"
            key="tablero"
            icon={<CkIcon className="side-menu-icon" name="dashboard-bars" />}
          >
            Tablero
          </Menu.Item>
          <Menu.Item
            hidden={!isWSEnabled}
            className="side-menu-dashboard"
            key="citas"
            icon={<CkIcon className="side-menu-icon" name="calendar-grid" />}
          >
            Citas
          </Menu.Item>
          <Menu.Item
            hidden={!isWSEnabled}
            className="side-menu-dashboard"
            key="chats"
            icon={<CkIcon className="side-menu-icon" name="chat" />}
          >
            Chats
          </Menu.Item>
          <Menu.Item
            hidden={isMec}
            className="side-menu-dashboard"
            key="mis-talleres"
            icon={<CkIcon className="side-menu-icon" name="workshop-car" />}
          >
            Mis talleres
          </Menu.Item>
          <Menu.Item
            hidden={isMec}
            className="side-menu-dashboard"
            key="mis-clientes"
            icon={<CkIcon className="side-menu-icon" name="clients" />}
          >
            Mis clientes
          </Menu.Item>
          <Menu.Item
            className="side-menu-dashboard"
            key="blog"
            icon={<CkIcon className="side-menu-icon" name="blog" alt="Blog" />}
          >
            Blog
          </Menu.Item>
          {/* {!isMec && ( */}
          {/*   <Menu.Item */}
          {/*     disabled={loadPro} */}
          {/*     hidden={isMec} */}
          {/*     className="side-menu-dashboard force-white" */}
          {/*     key="pro" */}
          {/*     {...(loadPro */}
          {/*       ? { */}
          {/*         icon: <Spin size="small" />, */}
          {/*       } */}
          {/*       : { */}
          {/*         icon: ( */}
          {/*           <CkIcon className="side-menu-icon" name="carker-pro" /> */}
          {/*         ), */}
          {/*       })} */}
          {/*   > */}
          {/*     {!loadPro ? "CarKer PRO" : "Cargando..."} */}
          {/*   </Menu.Item> */}
          {/* )} */}
          <Menu.Item
            className="side-menu-dashboard"
            key="usos-de-carker"
            icon={<CkIcon className="side-menu-icon" name="hand-together" />}
            id="HEADER004"
            onClick={handleAnalytics}
          >
            Usos y compromisos
          </Menu.Item>

          <Menu.Item
            className={[
              "side-menu-dashboard",
              "support",
              ...(openExtraOptions ? ["expanded"] : [""]),
            ].join(" ")}
            key="soporte"
            icon={<CkIcon className="side-menu-icon" name="headset" />}
            onClick={(ev) => {
              handleAnalytics(ev);
              if (expandedMenu === false) dispatch(toogleExpandedMenu(true));
              setOpenExtraOptions((prevState) => !prevState);
            }}
            id="HEADER007"
          >
            Contáctanos
            <CkIcon name={openExtraOptions ? "caret-up" : "caret-down"} />
          </Menu.Item>
        </Menu>
        <div
          className={[
            "contact-menu",
            ...(openExtraOptions ? ["expanded"] : []),
          ].join(" ")}
        >
          <CkButton
            href="https://wa.me/+525564359958"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(ev) => {
              handleAnalytics(ev);
              clickHandler({ key: "whatsapp" });
            }}
            variant="text"
            color="white"
            icon="whatsapp-outline"
            id="PHEADR001"
          >
            Escribir por WhatsApp
          </CkButton>
          <CkButton
            href="tel:5522820800"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(ev) => {
              handleAnalytics(ev);
              clickHandler({ key: "llamar" });
            }}
            variant="text"
            color="white"
            icon="phone"
            id="PHEADR002"
          >
            Llamar al 55 2282 0800
          </CkButton>
          <CkButton
            href="mailto:soporte@carker.com.mx"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(ev) => {
              handleAnalytics(ev);
              clickHandler({ key: "correo" });
            }}
            variant="text"
            color="white"
            icon="envelop"
            id="PHEADR003"
          >
            Enviar correo electrónico
          </CkButton>
        </div>
        {expandedMenu && (
          <div
            style={{
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "300px",
              paddingLeft: "61px",
              transition:
                "background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                style={{
                  fontSize: "12px",
                  color: "#444444",
                  paddingLeft: "5px",
                }}
                href="/terminosycondiciones"
              >
                Términos y condiciones
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (windowSize.width > 992)
    return (
      <>
        <Sider
          className={[
            "side-menu-sider",
            ...(expandedMenu ? ["expanded"] : []),
          ].join(" ")}
        >
          <ToogleMenuButton />
          <Content />
        </Sider>
      </>
    );

  return (
    <Drawer
      open={windowSize.width < 992 && expandedMenu}
      placement="left"
      className="side-menu-drawer"
      destroyOnClose
      zIndex={5000}
      maskClosable={true}
    >
      <Content />
    </Drawer>
  );
};

export default memo(DesktopSideBar);
