import React, { FC, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { fetchPro } from "shared";
import DesktopSideBar from "./DesktopSideBar";
import { CkMessage } from "../../../CkUI";
interface IProps {
  onSelect: (evt: any) => void;
  isMec: boolean;
}
const SideBar: FC<IProps> = ({ onSelect, isMec }) => {
  const dispatch = useDispatch();

  const [loadPro, setLoadPro] = useState<boolean>(false);

  const getProLink = async () => {
    const link: any = await dispatch(fetchPro());
    if (link && link.payload) {
      setLoadPro(false);
      // window.open(`${link.payload.url_login}`);
      window.location.href = `${link.payload.url_login}`;
    }
  };

  const onChange = (evt: any) => {
    if (evt.key.includes("pro")) {
      setLoadPro(true);
      CkMessage({type: "loading", text: "Cargando...", duration: 5});
      getProLink();
      return;
    }

    if (!["soporte", "whatsapp", "llamar", "correo"].includes(evt.key)) {
      onSelect(evt);
      return;
    }
  };

  return <DesktopSideBar onChange={onChange} isMec={isMec} loadPro={loadPro} />;
};

export default memo(SideBar);
