import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Popover, Radio, DatePicker, message } from "antd";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FilterOutlined } from "@ant-design/icons";
import {
  RightArrowIcon,
} from "../../../assets/SvgIcons";
import {
  CommunicationAPI,
  ConfigurationsAPI,
} from "../../../app/Services/axios";
import { PackageCodeType, Workshop } from "shared/src/data-models/Workshop";
import { useSelector, useDispatch } from "react-redux";
import {
  getWorkshops,
  getWorkshopSelected,
  setCurrentSection,
} from "../../../stores";
import { Vehicle } from "shared/src/data-models/EndConsumer";
import moment from "moment";
import { useIsMobileScreen } from "../../../app/Utilities/WindowSize";
import EntriesList from "./EntriesList";
import SideRequestDrawer from "./SideRequestDrawer";
import { CkButton, CkRadio, CkMessage } from "../../../CkUI";
import "./styles.css";

interface IProps {}

const { Option } = Select;
const { RangePicker } = DatePicker;

type ISortCriteria = "date" | "response";
type IFilterCriteria = "";

type Tsort = "ASC" | "DESC";
type TstatusFilter = "REPLY" | "PEND" | "CLOS" | "";
type TtypeFilter = "QUOTE" | "REQUEST" | "";
type TdateFilter = "week" | "month" | "range" | "";

interface ISortSelectOptions {
  label: string;
  values: {
    sortOrder: Tsort;
    sortDesc: boolean;
    sortCriteria: ISortCriteria;
  };
}

// Sort Options
const sortOptions: ISortSelectOptions[] = [
  {
    label: "Más reciente",
    values: {
      sortOrder: "DESC",
      sortDesc: true,
      sortCriteria: "date",
    },
  },
  {
    label: "Más antiguos",
    values: {
      sortOrder: "ASC",
      sortDesc: false,
      sortCriteria: "date",
    },
  },
  /* {
    label: "Sin responder",
    values: {
      sortDesc: true,
      sortCriteria: "response",
    },
  } */
];

export interface IRequest {
  newRequest: boolean;
  createdDate: string;
  isNewMessage: boolean;
  isWorkshopMessage: boolean;
  lastMessage: string;
  requestId: string;
  statusCode: string;
  typeCode: string;
  workshop: {
    email: string;
    id: string;
    name: string;
    sucursalName: string;
    workshopPhoroUrl: string;
  };
  vehicle: any;
  appointment: any;
}

interface Message {
  id: string;
  requestId: string;
  isWorkshopMessage: boolean;
  message: string;
  createdDate: string;
  senderName: string;
  attachments: any[];
}
interface EndConsumer {
  email: string;
  id: string;
  lastName: string;
  name: string;
  phone: string;
  sex: string;
  zipCode: string;
}

export interface Service {
  comment: string;
  createdBy: any;
  createdById: number;
  endConsumerRequestId: number;
  price: number;
  serviceCode: string;
  serviceName: string;
}

export interface Package {
  servicePackageCode: string;
  servicePackageDesc: string;
  servicePackageTypeCode: string;
  servicePackageTypeDesc: string;
}

export interface Request {
  packages: Package[];
  createdDate: string;
  endConsumer: EndConsumer;
  endConsumerRequestStatus: {
    requestStatusCode: string;
    requestStatusDesc: string;
  };
  endConsumerRequestType: {
    requestTypeCode: string;
    requestTypeDesc: string;
  };
  customService: number | null;
  customServiceTier: PackageCodeType | null;
  id: string;
  messages: Message[];
  services: Service[];
  statusCode: string;
  typeCode: string;
  vehicle: Vehicle;
  workshop: Workshop;
  workshopAppointment: any;
  workshopAppointmentId: any;
  workshopUserId: any;
}

interface IfiltersBakcup {
  status: TstatusFilter;
  type: TtypeFilter;
  date: TdateFilter;
}
const defaultFilters: IfiltersBakcup = {
  status: "",
  type: "",
  date: "",
};
const RequestDashboard: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const isMobileScreen = useIsMobileScreen(992);
  const { selectedId } = useParams<{ selectedId?: string }>();

  // Page Size
  const pageSize = 20;
  const page = useRef<number>(1);
  const firstRender = useRef<boolean>(true);
  const filtersBackup = useRef<IfiltersBakcup>({
    status: "",
    type: "",
    date: "",
  });

  //Page States To LoadMore
  const [limitReached, setLimitReached] = useState<boolean>(false);
  const [drawerContainer, setDrawerContainer] = useState<"body" | "div">("div");

  //States
  const [requestsList, setRequestsList] = useState<any[]>([]);

  //Popover
  const [popoverShow, setPopoverShow] = useState<boolean>(false);

  //ModalData
  const [requestSelected, setRequestSelected] = useState<Request | undefined>(
    undefined
  );
  const [requestSearched, setRequestSearched] = useState<Request | undefined>(
    undefined
  );

  //Page Loading
  const [loading, setLoading] = useState<boolean>(false);

  //sasToken
  const [sasToken, setSasToken] = useState<string>("");

  // Filters
  const workshops: Workshop[] = useSelector(getWorkshops);

  const [statusFilter, setStatusFilter] = useState<TstatusFilter>(
    defaultFilters.status
  );
  const [typeFilter, setTypeFilter] = useState<TtypeFilter>(
    defaultFilters.type
  );
  const [dateFilter, setDateFilter] = useState<TdateFilter>(
    defaultFilters.date
  );
  const [dateFilterRange, setDateFilterRange] = useState<moment.Moment[]>([
    moment().subtract(7, "days"),
    moment(),
  ]);
  const [sortBy, setSortBy] = useState<Tsort>(sortOptions[0].values.sortOrder);

  const workshopSelected: number = useSelector(getWorkshopSelected);

  const statusFilterOptions = [
    {
      label: "Respondido",
      value: "REPLY",
      onClick: () => handleStatus("REPLY")
    },
    {
      label: "Sin responder",
      value: "PEND",
      onClick: () => handleStatus("PEND")
    },
    {
      label: "Cerrado",
      value: "CLOS",
      onClick: () => handleStatus("CLOS")
    },
    {
      label: "Ver todo",
      value: "",
      onClick: () => handleStatus("")
    }
  ];

  const typeFilterOptions = [
    {
      label: "Cotización",
      value: "QUOTE",
      onClick: () => handleType("QUOTE")
    },
    {
      label: "Consulta",
      value: "REQUEST",
      onClick: () => handleType("REQUEST")
    },
    {
      label: "Ver todo",
      value: "",
      onClick: () => handleType("")
    }
  ];

  /**
   *
   *  Functions
   *
   **/
  const handleStatus = (status: TstatusFilter) =>
    setStatusFilter(statusFilter === status ? "" : status);
  const handleType = (type: TtypeFilter) =>
    setTypeFilter(typeFilter === type ? "" : type);
  const handleDate = (date: TdateFilter) =>
    setDateFilter(dateFilter === date ? "" : date);

  const getDrawerContainer = (): HTMLElement => {
    const parentContainer: HTMLElement | null =
      document.querySelector(".request-dashboard");
    const divContainer: HTMLElement | null = document.querySelector(
      ".entry-request-container"
    );

    if (parentContainer === null || divContainer === null) {
      setDrawerContainer("body");
      return document.body;
    }

    if (parentContainer.getBoundingClientRect().width >= 860) {
      setDrawerContainer("div");
      return divContainer;
    }

    setDrawerContainer("body");
    return document.body;
  };

  const updateRequestList = (requestId: number, message: string) => {
    const prev: any[] = [...requestsList];
    const index: number = prev.findIndex((res) => res.requestId == requestId);

    prev[index].lastMessage = message;
    prev[index].isWorkshopMessage = false;
    prev[index].createdDate = moment().add(6, "hour").format();

    setRequestsList([...prev]);
  };

  const addRequestToList = (newRequest: any) => {
    setRequestsList([newRequest, ...requestsList]);
  };

  /**
   *
   * @param reset if is set to true, pagination will be reseted and the stored conversations in the state will bi ignored
   * @param forceFilters is an object is passed, this object will be used to filter. This is needed when the function is called if we change several filters
   * @returns
   */
  const getData = async (reset?: boolean, forceFilters?: IfiltersBakcup) => {
    if (loading) return;
    var localLimiteReached = limitReached;
    if (reset) {
      setRequestsList([]);
      limitReached && setLimitReached(false);
      localLimiteReached = false;
      page.current = 1;
    }

    // if (!loading) setLoading(true);
    if (localLimiteReached === true) return;

    // To get sasToken
    await ConfigurationsAPI.getSasToken()
      .then((values) => {
        setSasToken(values.data);
      })
      .catch((error: Error) => console.error(error));

    /**
     * Define Filters
     */
    const filters: IfiltersBakcup = forceFilters
      ? forceFilters
      : {
          status: statusFilter,
          type: typeFilter,
          date: dateFilter,
        };
    // Date
    let initialDate: string;
    let finalDate: string;
    if (filters.date === "range") {
      initialDate = dateFilterRange[0].startOf("day").toISOString();
      finalDate = dateFilterRange[1].endOf("day").toISOString();
    } else if (filters.date === "") {
      initialDate = "";
      finalDate = "";
    } else {
      initialDate = moment().startOf(filters.date).toISOString();
      finalDate = moment().endOf(filters.date).toISOString();
    }

    const requestCommunications = await CommunicationAPI.getRequests({
      workshopId: workshops[workshopSelected].id!,
      dateFrom: initialDate,
      dateTo: finalDate,
      ...(filters.status !== "" ? { requestStatus: filters.status } : {}),
      ...(filters.type !== "" ? { requestType: filters.type } : {}),
      orderType: sortBy,
      page: page.current,
      pageSize: pageSize,
    })
      .then((response) => response.data)
      .catch((error) => undefined);
    if (requestCommunications === undefined) {
      CkMessage({type: "error", text: "Se produjo un error al buscar las conversaciones"});

      return;
    }

    const { pages, requests } = requestCommunications;

    // Set messages
    const newRequestList = [
      // wipe previous data if reset is true
      ...(reset === true ? [] : requestsList),
      ...(requestSearched !== undefined
        ? requests.filter((request) => request.id !== requestSearched.id)
        : requests),
    ];
    setRequestsList(newRequestList);

    if (
      firstRender.current === false &&
      requestSelected !== undefined &&
      requests.some((request: Request) => request.id === requestSelected.id) ===
        false
    ) {
      const drawerContainer = getDrawerContainer();
      drawerContainer.classList.contains("entry-request-container") &&
        setRequestSelected(requests[0]);
    }

    if (
      firstRender.current === false &&
      requestSelected !== undefined &&
      requests.filter(
        (request: Request) => request.id === requestSelected.id
      )[0]
    ) {
      setRequestSelected(
        requests.filter(
          (request: Request) => request.id === requestSelected.id
        )[0]
      );
    }

    if (firstRender.current) {
      const drawerContainer = getDrawerContainer();
      drawerContainer.classList.contains("entry-request-container") &&
        setRequestSelected(requests[0]);

      if (selectedId) {
        const selectedConversation = newRequestList.filter(
          (request: Request) => request.id + "" === selectedId + ""
        )[0];
        console.info("selectedConversation", selectedConversation);
        if (selectedConversation !== undefined) {
          setRequestSelected(selectedConversation);
        } else {
          const requestDefaultConversation = await CommunicationAPI.getRequests(
            {
              workshopId: workshops[workshopSelected].id!,
              dateFrom: "",
              dateTo: "",
              orderType: sortBy,
              page: 1,
              pageSize: 1,
              endConsumerRequestId: selectedId,
            }
          )
            .then((response) => response.data)
            .catch((error) => undefined);
          if (
            requestDefaultConversation &&
            requestDefaultConversation.requests &&
            requestDefaultConversation.requests[0] !== undefined
          ) {
            const { requests } = requestDefaultConversation;
            setRequestSearched(requests[0]);
            setRequestSelected(requests[0]);
            setRequestsList([requests[0], ...newRequestList]);
          }
        }
      }

      firstRender.current = false;
    }

    page.current += 1;
    if (page.current > pages || requests.length < pageSize)
      setLimitReached(true);

    setLoading(false);
  };

  const clearFitlers = () => {
    setStatusFilter(defaultFilters.status);
    setTypeFilter(defaultFilters.type);
    setDateFilter(defaultFilters.date);
    popoverShow && setPopoverShow(false);
    getData(true, defaultFilters);
  };

  const applyFilters = () => {
    setPopoverShow(false);
    getData(true);
  };

  const setFiltersBackup = () =>
    (filtersBackup.current = {
      status: statusFilter,
      type: typeFilter,
      date: dateFilter,
    });

  const applyFiltersBackup = () => {
    setStatusFilter(filtersBackup.current.status);
    setTypeFilter(filtersBackup.current.type);
    setDateFilter(filtersBackup.current.date);
  };

  /**
   *
   *  Components
   *
   **/
  const PopoverRender = () => (
    <div className="request-popover-filter-render">
      <CkButton
        variant="text"
        shape="circle"
        className="close-button"
        icon="close"
        onClick={() => {
          applyFiltersBackup();
          setPopoverShow(false);
        }}
      />
      <p>Filtrar conversaciones:</p>
      <div className="filter-group">
        <p>Estatus:</p>
        <CkRadio 
          noMargin
          options={statusFilterOptions}
          radioGroupProps={{
            value: statusFilter
          }}
        />
      </div>
      {/* <Divider /> */}
      <div className="filter-group">
        <p>Tipo:</p>
        <CkRadio 
          noMargin
          options={typeFilterOptions}
          radioGroupProps={{
            value: typeFilter
          }}
        />
      </div>

      <div className="filter-group">
        <p>Fecha:</p>
        <Radio.Group value={dateFilter}>
          <Radio
            onClick={() => handleDate("range")}
            className="--custom range-option"
            value={"range"}
          >
            Rango de fechas
          </Radio>
          <RangePicker
            className="--custom"
            disabled={dateFilter !== "range"}
            popupClassName="filter-range-date-picker"
            disabledDate={(date) => moment(date).isAfter(moment())}
            onChange={(date) =>
              setDateFilterRange([
                date ? (date[0] as moment.Moment) : moment(),
                date ? (date[1] as moment.Moment) : moment(),
              ])
            }
            separator={<RightArrowIcon />}
            value={dateFilterRange}
          />
          <Radio
            onClick={() => handleDate("week")}
            className="--custom"
            value={"week"}
          >
            Ver semana
          </Radio>
          <Radio
            onClick={() => handleDate("month")}
            className="--custom"
            value={"month"}
          >
            Ver mes
          </Radio>
          <Radio onClick={() => handleDate("")} className="--custom" value={""}>
            Ver todo
          </Radio>
        </Radio.Group>
      </div>

      <CkButton onClick={() => applyFilters()}>
        Aplicar
      </CkButton>
      <CkButton variant="secondary" onClick={() => clearFitlers()}>
        Limpiar
      </CkButton>
    </div>
  );

  /**
   *
   *  Effects
   *
   **/
  useEffect(() => {
    // !!workshops && !!workshops[workshopSelected] && getData();
    dispatch(setCurrentSection(""));
  }, []);

  useEffect(() => {
    popoverShow && setPopoverShow(false);
  }, [isMobileScreen]);

  useEffect(() => {
    !!workshops && !!workshops[workshopSelected] && getData(true);
    popoverShow && setPopoverShow(false);
  }, [workshopSelected, workshops]);

  useEffect(() => {
    popoverShow && setFiltersBackup();
  }, [popoverShow]);

  useEffect(() => {
    firstRender.current === false && sortBy && getData(true);
  }, [sortBy]);

  useEffect(() => {
    requestSelected && popoverShow && setPopoverShow(false);
  }, [requestSelected]);

  return (
    <>
      <Helmet>
        <title>CarKer Para Talleres Automotrices</title>‍
        <meta
          name="description"
          content="Digitaliza tu taller automotriz formando parte de la plataforma CarKer y conecta con más clientes."
          data-react-helmet="true"
        />
      </Helmet>
      <section className="request-dashboard">
        <div className="entries-list-container">
          <div className="filters-container">
            <div className="buttons-container">
              <Popover
                overlayClassName="request-popover-filter"
                open={popoverShow}
                content={PopoverRender}
                trigger="click"
                zIndex={5000}
                arrowPointAtCenter={false}
                placement="bottomRight"
              >
                <CkButton
                  className="filter-button"
                  shape="circle"
                  icon="filters"
                  variant="text"
                  onClick={() => setPopoverShow(true)}
                />
              </Popover>
              <CkButton
                className="refresh-button"
                shape="circle"
                icon="refresh"
                variant="text"
                onClick={() => getData(true)}
              />
            </div>

            <div className="filter-select">
              <p>Ordena por:</p>
              <Select
                className="--custom filter-filled-select"
                defaultValue={sortBy}
                onChange={(selectedValue: Tsort) => setSortBy(selectedValue)}
              >
                {sortOptions.map((option, index) => (
                  <Option key={index} value={option.values.sortOrder}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <EntriesList
            requestsList={requestsList}
            setRequestSelected={setRequestSelected}
            requestSelected={requestSelected}
            sasToken={sasToken}
            loading={loading}
            getData={getData}
            limitReached={limitReached}
            setShowFiltersPopover={setPopoverShow}
          />
        </div>

        <SideRequestDrawer
          updateRequestList={updateRequestList}
          addRequestToList={addRequestToList}
          requestSelected={requestSelected!}
          setRequestSelected={setRequestSelected}
          workshop={workshops[workshopSelected]}
          reload={() => getData(true)}
          loadingList={loading}
          isMobileScreen={isMobileScreen}
          drawerContainer={drawerContainer}
          getDrawerContainer={getDrawerContainer}
        />
      </section>
    </>
  );
};

export default RequestDashboard;
