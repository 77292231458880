import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This function returns an URLSearchParams that can be used to query search params in url
 * @returns URLSearchParams object
 */
const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
